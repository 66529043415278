<template>
  <RetroType :lines="lines" :class="section" :charDelay="charDelay" @printdone="printDone" @printstart="printStart">
  </RetroType>
</template>
<script>
/** @typedef {{
 *  index: number,
 *  replacement: string,
 *  defaultValue?: string,
 *  replaceWith: (replacer: string) => void
 * }} CommandArg */

import LinesExtended from "../content/lines.extended"
import RetroType from "./RetroType.vue"
import templateService from "../services/template.service"

export default {
  components: {
    RetroType
  },
  props: {
    section: {
      type: String,
      default: "home"
    },
    arguments: {
      type: String,
      default: ""
    },
    charDelay: {
      type: Number,
      default: 4
    },
  },
  data: () => ({
    lines: [],
    availableLines: { ...LinesExtended },
    stopAcceptLines: () => { },
  }),
  computed: {
    args() {
      if (!this.arguments || !this.arguments.trim())
        return [];
      return [...[...this.arguments].reduce((words, char) => {
        if (char === '"') {
          if (words.quoteStarted) {
            words.quoteStarted = false;
            words.push('');
            return words;
          }
          words.quoteStarted = true;
          return words;
        }
        if (char === ' ' && !words.quoteStarted) {
          words.push('');
          return words;
        }
        words[words.length - 1] += char;
        return words;
      }, [''])]
        .filter(a => a);
    }
  },
  methods: {
    printDone() {
      this.$emit('retro-print-done');
    },
    printStart() {
      this.$emit('retro-print-start');
    },
    handleSection(newSection) {
      if (!this.availableLines.hasOwnProperty(newSection)) return;
      this.stopAcceptLines();
      const linesOrigin = this.availableLines[newSection];
      const lines = linesOrigin.copy ? linesOrigin.copy() : [...linesOrigin];
      const regex = /({(\d+):?([^}]+)?})/ig;
      /** @type CommandArg[]  */
      const args = [];
      for (const i in lines) {
        const line = lines[i];
        let arg = null;
        // eslint-disable-next-line no-cond-assign
        while (arg = regex.exec(line)) {
          if (arg.length < 4) continue;
          const [replacement, , index, defaultValue] = arg;
          const replaceWith = (replacer = '') => {
            lines[i] = lines[i].replace(replacement, replacer)
          }
          args.push({ replacement, index, defaultValue, replaceWith })
        }
      }
      for (const arg of args) {
        if (false === arg.index in this.args) {
          arg.replaceWith(arg.defaultValue || '');
          continue;
        }
        arg.replaceWith(this.args[arg.index])
      }
      if (this.args.length > 0 && lines.applyArgs)
        lines.applyArgs(this.args);
      if (lines.acceptLines) {
        // если есть какой-то свой механизм выдачи строк,
        // то кормим ему, куда эти строки присваивать
        const linesAccepting = lines.acceptLines(newLines => this.lines = newLines);
        this.stopAcceptLines = linesAccepting.stop;
      }
      this.lines = [...lines];
    }
  },
  watch: {
    section: {
      immediate: true,
      handler(newSection) {
        this.handleSection(newSection);
      }
    },
    arguments() {
      this.handleSection(this.section);
    }
  },
  async mounted() {
    const textLines = await window.getLoadedJSON('textLines');

    Object.keys(textLines).forEach(key => {
      this.$set(this.availableLines, key, textLines[key].map(text => templateService.replace(text)))
    });

    this.handleSection(this.section)
  }


};
</script>
<style>

</style>