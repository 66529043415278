/* eslint-disable no-console */
import LinesExtended from "../../content/lines.extended"
import templateService from "../../services/template.service"

export default {
    name: 'app',
    components: {},

    data() {
        return {
            lines: { ...LinesExtended },
            something: [
                {
                    text:"DODO IS",
                    url:"dodois",
                    subUrl:[
                        {
                            text:"Сервис управления рестораном",
                            url:"restService"
                        },
                        {
                            text:"Сервис привлечения и обслуживания клиентов",
                            url:"customerService"
                        },
                        {
                            text:"Технические сервисы",
                            url:"techService"
                        }
                    ]
                },
                {
                    text:"Сайт ДоДо Пицца",
                    url:"dodopizza"
                },
                {
                    text:"Мобильные приложения",
                    url:"alldodomobile",
                    subUrl:[
                        {
                            text:"Додо пицца",
                            url:"dodoMobile"
                        },
                        {
                            text:"Донер 42",
                            url:"donerMobile"
                        },
                        {
                            text:"Дринкит",
                            url:"drinkitMobile"
                        },
                        {
                            text:"Для курьеров",
                            url:"courierMobile"
                        }
                    ]
                }
            ]
        };
    },

    methods: {
        sendMetrika(eventId) {
            window.ym.send(eventId);
        },
    },

    async mounted() {
        const textLines = await window.getLoadedJSON('textLines');
        Object.keys(textLines).forEach(key =>
            this.$set(this.lines, key, textLines[key].map(text => templateService.replace(text)))
        );

        document.getElementById("activity").hidden = true;

        document.getElementById("legal").hidden = true;
    },

    async beforeDestroy(){
        document.getElementById("activity").hidden = false;

        document.getElementById("legal").hidden = false;
    }
}