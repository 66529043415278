/* eslint-disable no-console */
import Content from '../../components/Content.vue'
import Jobs from '../../components/Jobs.vue'
import MiniHelp from '../../components/MiniHelp.vue'
import SocialLinks from '../../components/SocialLinks.vue'
import SideProjects from '../../components/SideProjects.vue'
import RetroInput from '../../components/RetroInput.vue'
import AnimatedSectionsList from '../../content/animated-sections';

export default {
  components: {
    Content,
    Jobs,
    MiniHelp,
    SocialLinks,
    SideProjects,
    RetroInput
  },

  data: () => ({
    x: '',
    charDelay: 4,
    isPrinted: false,
    showJobs: false,
    section: 'home',
    args: '',
    cmd: 'home',
    lastCmd: '',
    historyCmd: [],
    historyPos: 0,
    inputValue: '',
    autocompleteList: [ /* commandList + commandAliases.split('|') */ ],
    commandList: ['home', 'stack', 'projects', 'business', 'jobs', 'test', 'help', 'ping', 'cowsay', 'pizza', 'game', 'sudo', 'rm'],
    commandAliases: {
      q: 'q|quit|exit|back|return|leave',
      jobs: 'careers|vc|job|vacancy|vacancies',
      game: 'game|play|fun|egg|snake',
      stack: 'techstack|tech|technology|framework',
      test: 'test',
      github: 'github',
      habr: 'habr',
      vk: 'vk',
      telegram: 'telegram',
      youtube: 'youtube',
      twitter: 'twitter',
    },
    mobile: false
  }),

  computed: {
    isAnimated() {
      return AnimatedSectionsList.includes(this.section);
    },
  },

  methods: {
    sendMetrika(eventId) {
      window.ym.send(eventId, this.$store.devmode ? 'dev' : 'manager');
    },

    checkCmd() {
      if (!this.cmd) return;
      this.lastCmd = this.cmd !== '' ? this.cmd : this.lastCmd;
      const x = this.cmd.trim().split(' ');
      this.cmd = x[0].toLowerCase().replace(/[/-]/g, '');
      this.args = x.splice(1).join(' ');

      const alias = this.commandAliases;

      const aliasActions = {

        [alias.q]:
          (routeTo) => routeTo({ name: 'command', params: { command: 'home' } }),

        [alias.jobs]:
          () => this.cmd = 'jobs',

        [alias.stack]:
          () => this.cmd = 'stack',

        [alias.test]:
          (routeTo) => routeTo({ name: 'test' }),

        [alias.game]:
          (routeTo) => routeTo({ name: 'game' }),

        [alias.github]: () => {
          this.cmd = '';
          window.open(window.socialLinks.githubLink.href, '_blank').focus();
        },
        [alias.habr]: () => {
          this.cmd = '';
          window.open(window.socialLinks.habrLink.href, '_blank').focus();
        },
        [alias.vk]: () => {
          this.cmd = '';
          window.open(window.socialLinks.vkLink.href, '_blank').focus();
        },
        [alias.telegram]: () => {
          this.cmd = '';
          window.open(window.socialLinks.tgLink.href, '_blank').focus();
        },
        [alias.youtube]: () => {
          this.cmd = '';
          window.open(window.socialLinks.ytLink.href, '_blank').focus();
        },
        [alias.twitter]: () => {
          this.cmd = '';
          window.open(window.socialLinks.twLink.href, '_blank').focus();
        },
      };

      const aliasKey = Object.keys(aliasActions).find(key => key.split('|').some(x => x === this.cmd))
      let isRouted = false;
      const routeTo = (routeParams) => {
        isRouted = true;
        return this.$router.push(routeParams)
      };
      if (aliasKey) {
        aliasActions[aliasKey](routeTo);
        if (isRouted) {
          this.cmd = "";
          return true;
        }
      }
      else if (this.cmd !== '') {
        this.section = 'notexist'
      }

      //Проверка на основные команды
      if (this.commandList.includes(this.cmd)) {
        this.section = this.cmd;
        this.isPrinted = false;

        //пушим команды в историю, чтобы стрелками выбирать их
        if (this.$store.historyCmd.last() !== this.cmd)
          this.$store.historyCmd.push(this.cmd);
        this.historyPos = this.$store.historyCmd.length;

        if (this.$route.params.command !== this.cmd)
          this.$router.push({ name: 'command', params: { command: this.cmd } })
      }

      //Очищаем переменную и инпут
      this.cmd = "";
      return true;
    },

    skipTyping() {
      this.charDelay = 0;
      setTimeout(() => this.charDelay = 4, 50);
    },

    moveHistory: (() => {
      let tempCmd = '';
      return function (step) {
        if (step === 0)
          return this.historyPos = this.$store.historyCmd.length;
        if (this.historyPos === this.$store.historyCmd.length)
          tempCmd = this.cmd;
        this.historyPos = Math.max(0, Math.min(this.historyPos + step, this.$store.historyCmd.length));
        if (this.historyPos === this.$store.historyCmd.length)
          return this.cmd = tempCmd;
        this.cmd = this.$store.historyCmd[this.historyPos];
      };
    })(),

    scrollTop() {
      setTimeout(() => {
        if (this.$refs.developer)
          this.$refs.developer.scrollTo(0, 0);
      }, 20);
    },

    scrollDown() {
      setTimeout(() => {
        if (this.$refs.developer)
          this.$refs.developer.scrollTo(0, 1500);
      }, 30);
    },

    mobileCheck() {
      this.mobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    }
  },

  watch: {
    '$route'(to, from) {
      this.cmd = to.params.command;
      if (this.cmd) {
        if (this.args) this.cmd += ' ' + this.args;
        this.checkCmd();
        this.scrollTop();
      }

      if (to.name == 'commandsEmpty') {
        this.section = 'home'
        this.lastCmd = ''
      }
    },
  },

  created() {
    this.autocompleteList = [...this.commandList];
    Object.values(this.commandAliases).forEach(alias =>
      alias.split('|').forEach(a => this.autocompleteList.push(a))
    );
    this.cmd = this.$route.params.command;
    if (this.cmd)
      this.checkCmd();
  },

  mounted() {
    this.mobileCheck();
  }
}