/* eslint-disable no-console */
import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import routes from './routes';
import BUILD_VERSION from './build/version';

Vue.use(VueRouter)
Vue.config.productionTip = false
Vue.prototype.$store = {
  devmode: true,
  prevUrl: null,
  historyCmd: [],
};

new Vue({
  render: h => h(App),
  router: new VueRouter({
    base: '/',
    mode: 'history',
    routes,
  })
}).$mount('#app')

const body = document.body;

Array.prototype.last = function () {
  if (this.length === 0) return this[0];
  return this[this.length - 1];
}

window.onresize = () => {
  const screenSize = body.getBoundingClientRect();
  const el = document.getElementById('pc');

  if (!el) return;

  const scale = Math.min (
    (screenSize.width - 100) / el.clientWidth,
    (screenSize.height - 20) / el.clientHeight
  )

  el.style.transform = "matrix(" + scale + ",0,0," + scale + ", 1, 1)";
}

function setSongSettings (){
  const song = document.getElementById("audio");

  song.volume = 0.5;
  song.pause();
}

window.onresize();
setSongSettings();

console.info('%cDodo Pizza Engineering – v' + BUILD_VERSION, `
display: block;
width: 100%;
padding: 220px 20px 10px;
color: #D1FB84;
background-color: #25291F;
background-image: url(https://dodo.dev/${require('./assets/svg/bird-1.svg')});
background-size: contain;
background-position: 50% -15px;
background-repeat: no-repeat;
font: 800 1.3em monospace;
text-shadow: 0 0 10px #D1FB84;
border-radius: 5px`);