<template>
    <div ref="retroType" class='retro-type'>
        <div class='retro-type-line' v-for='(line, lineIndex) of allLines' :key='lineIndex' :class='lineClassName(lineIndex)'>
            <span class='retro-type-word' v-for='(word, wordIndex) of words(line)' :key='wordIndex'>
                <span
                    v-for='(char, charIndex) of word'
                    v-html='char === " " ? "&nbsp;" : char'
                    class='retro-type-char'
                    :key='charIndex'
                    :class='{ shown: isPrinted({ lineIndex, wordIndex, charIndex }) }'
                    ></span>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        charDelay: {
            type: Number,
            default: 4,
        },
        startDelay: {
            type: Number,
            default: 100,
        },
        stopped: {
            type: Boolean,
            default: false,
        },
        lines: {
            type: Array,
            default: () => [],
        },
        lineClassNames: {
            type: Array,
            defautl: () => [],
        }
    },
    data: () => ({
        allLines: [],
        currentLines: [],
        linesToPrint: [],
        timeoutId: null,
    }),
    computed: {
        lineIndex() {
            const i = this.currentLines.length - 1;
            return (i >= 0) ? i : -1;
        },
        lineClassName() {
            if (!this.lineClassNames || this.lineClassNames.length < 1)
                return (lineIndex) => this.isPrinted({ lineIndex }) ? ' shown' : '';
            return (lineIndex) => {
                const className = this.lineClassNames[lineIndex] || '';
                return (className + (this.isPrinted({ lineIndex }) ? ' shown' : '')).trim();
            }
        },
        currentLine() {
            return (this.lineIndex >= 0)
                ? this.currentLines[this.lineIndex]
                : '';
        },
        targetLine() {
            return (this.lineIndex >= 0 && this.linesToPrint[this.lineIndex])
                ? this.linesToPrint[this.lineIndex]
                : '';
        },
        isLineDone() {
            return this.currentLine.length >= this.targetLine.length;
        },
        hasNextLine() {
            return this.currentLines.length < this.linesToPrint.length;
        },
        words() {
           return function (line) {
                const words = line.split(` `).map(w => w+` `);
                if (words.length > 0) {
                    let lastWordInLine = words[words.length - 1];
                    lastWordInLine = lastWordInLine.substr(0, lastWordInLine.length - 1);
                    words[words.length - 1] = lastWordInLine;
                }
                return words;
           }
        },
        isPrinted() {
            return function ({ lineIndex, wordIndex = null, charIndex = null }) {
                if (!this.currentLines.hasOwnProperty(lineIndex))
                    return false;

                if (wordIndex === null && charIndex === null)
                    return true;

                const words = this.currentLines[lineIndex].split(` `).map(w => w+` `);
                if (!words.hasOwnProperty(wordIndex))
                    return false;
                if (charIndex !== null && !words[wordIndex][charIndex])
                    return false;

                return true;
            }
        },
    },
    methods: {
        skip() {
            this.currentLines = [ ...this.linesToPrint ];
        },
        nextChar() {
            if (!this.timeoutId)
              this.$emit('printstart');
            this.timeoutId = null;
            if (this.isLineDone && this.hasNextLine)
                this.currentLines.push('');
            if (this.isLineDone === false)
                this.$set(
                    this.currentLines,
                    this.lineIndex,
                    this.currentLines[this.lineIndex] + this.targetLine[this.currentLine.length]
                    );
            if (this.hasNextLine || !this.isLineDone)
                this.timeoutId = setTimeout(() => requestAnimationFrame(this.nextChar), this.charDelay);
            else
                this.$emit('printdone');
        }
    },
    watch: {
        charDelay(newDelay) {
            if (newDelay <= 0) return this.skip();
        },
      	stopped(needStop, isStoppedBefore) {
            if (!!isStoppedBefore === !!needStop) return;
            if (needStop) {
                if (!this.timeoutId) return;
                clearTimeout(this.timeoutId);
                this.timeoutId = null;
            }
            else {
                if (this.timeoutId) return;
                this.created();
            }
        },
        lines: {
            immediate: true,
            handler(updatedLines, oldLines) {
                if (this.timeoutId) {
                    clearTimeout(this.timeoutId);
                    this.timeoutId = null;
                }
                this.linesToPrint.splice(0);
                this.allLines.splice(0);
                if (!oldLines) oldLines = [];
                for (const i in updatedLines) {
                    if (!updatedLines.hasOwnProperty(i)) continue;
                    this.linesToPrint.push(updatedLines[i]);
                    this.allLines.push(updatedLines[i]);
                    if (oldLines[i] !== updatedLines[i]) {
                        this.currentLines.splice(i);
                        continue;
                    }
                }
                if (this.timeoutId) return
                this.nextChar();
            }
        }
    },
    created() {
        if (this.charDelay <= 0) return this.skip();
        if (this.startDelay > 0)
            requestAnimationFrame(this.nextChar);
    },
}
</script>

<style lang="scss" scoped>
.retro-type {
    text-align: left;

    &-line {
        display: none;

        &.shown { display: block; }
    }

    &-word {
        display: inline-block;
    }

    &-char {
        will-change: auto;
        display: none;

        &.shown {
            display: inline-block;
            animation: char-bg-fade 0.25s linear forwards;
        }
    }

    * { pointer-events: none; }
}

@keyframes char-bg-fade {
    0% { background: rgba(209,251,132,1); }
    100% { background: rgba(209,251,132,0); }
}
</style>