import Developer from './views/Developer/Developer.vue'
import Test from './views/Test/Test.vue'
import Manager from './views/Manager/Manager.vue'
import Game from './views/Game/Game.vue'
import DodoProjects from './views/Dodoprojects/DodoProjects.vue'

export default [
    {
        name: 'commandsEmpty',
        path: '/',
        component: Developer
    },
    {
        name: 'game',
        path: '/game',
        component: Game
    },
    {
        name: 'test',
        path: '/test',
        component: Test
    },
    {
        name: 'manager',
        path: '/manager',
        component: Manager,
    },
    {
        name: 'dodoprojects',
        path: '/dodoprojects',
        component: DodoProjects
    },
    {
        name: 'command',
        path: '/:command?',
        component: Developer
    }
]