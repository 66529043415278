/* eslint-disable no-console */
// import { results, questions } from '../../content/test.json'
import SocialSharing from 'vue-social-sharing'

export default {
    components: {
        SocialSharing,
    },

    data: () => ({
        charDelay: 8,
        section: 'test',
        currentQuestionNumber: 0,
        inputValue: '',
        commandList: ['a', 'b'],
        green: 0,
        red: 0,
        blue: 0,
        yellow: 0,
        result: false,
        typeCode: "",
        type: "",
        role: "",
        description: "",
        testText: '',
        mobile: false,

        static: {
          results: {},
          questions: [],
        },
    }),

    computed: {
        current() {
            return this.static.questions[this.currentQuestionNumber];
        },

        answerText() {
            return function (letter) {
                return letter + ". " + (this.current['answer_' + letter] || '').replace(/\n/g, '<br>');
            }
        },
    },

    methods: {
        sendMetrika(eventId) {
            window.ym.send(eventId, this.$store.devmode ? 'dev' : 'manager');
        },

        testInit() {
            this.currentQuestionNumber = 0;
            this.green = 0;
            this.red = 0;
            this.blue = 0;
            this.yellow = 0;
            this.typeCode = "";
            this.type = "";
            this.role = "";
            this.result = false;
        },

        checkCmd(cmd) {
            if (cmd)
                cmd = cmd.toLowerCase().trim().replace(/[/-]/g, '');

            // Проверка на основные команды
            if (this.commandList.includes(cmd)) {
                this.charDelay = 2;

                if (cmd === 'a')
                    // example: this.green++
                    this[this.current.category]++;

                if (this.currentQuestionNumber < 19)
                    this.currentQuestionNumber += 1;
                else this.showResult()
            }
            return true;
        },

        showResult() {
            window.ym.send('testdone');
            const threshold = 3;
            const codeForType = [
                { type: 'green', false: 'I', true: 'E' },
                { type: 'red', false: 'N', true: 'S' },
                { type: 'blue', false: 'F', true: 'T' },
                { type: 'yellow', false: 'P', true: 'J' },
            ];

            for (const x of codeForType)
                this.typeCode += x[this[x.type] >= threshold];

            const { type, role, description } = this.static.results[this.typeCode];
            this.type = type;
            this.role = role;
            this.description = description;
            this.result = true;
            this.section = 'result';
        },

        sendSocialClickMetrika() {
            const event = arguments[0];
            if (!event.path) return;
            const shareButton = event.path.find(node => node.classList.contains('social-share'));
            if (!shareButton) return;
            let type = [...shareButton.classList].find(c => c.startsWith('metrika-'));
            if (!type) return;
            type = type.split('-')[1];
            window.ym.send(type, this.$store.devmode ? 'dev' : null);
        },

        onkeyup(event) {
            if (event.which === 27) {
                if (this.$store.prevUrl === null)
                    return this.$router.push(this.$store.devmode ? '/' : '/manager');
                return this.$router.go(-1);
            }

            if (this.result) return;

            const answers = {
                65: 'A',
                66: 'B',
                49: 'A',
                50: 'B'
            };

            if (false === event.which in answers) return;

            const answerElement = this.$refs[`answer` + answers[event.which]];
            if (!answerElement) return;
            answerElement.focus();
            setTimeout(() => {
                this.checkCmd(answers[event.which]);
                answerElement.blur();
            }, 150);
        },

        mobileCheck() {
            this.mobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        }
    },

    async mounted() {
        this.mobileCheck();

        this.$nextTick(() => {
            this.$refs.answerA.focus();
            this.$nextTick(() => this.$refs.answerA.blur());
        });

        this.testText = (await window.getLoadedJSON('textLines')).test.join('\n');
        this.$set(this, 'static', await window.getLoadedJSON('testContents'))
        document.addEventListener('keyup', this.onkeyup);
    },

    destroyed() {
        document.removeEventListener('keyup', this.onkeyup);
    }
}