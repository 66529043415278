<template>
  <div>
    <!-- cman -->
    <a
      href="https://teleg.run/dodocmanbot"
      target="_blank"
      class="button button-outer"
      @click="sendMetrika('cman')"
    >@dodocmanbot</a>

    <!-- Фрактальная пицца -->
    <a
      href="https://nplus1.ru/material/2018/12/21/prododoject"
      target="_blank"
      class="button button-outer"
      @click="sendMetrika('prododoject')"
    >Фрактальная пицца</a>

    <!-- АI пицца -->
    <a
      href="https://ai.dodopizza.com/"
      target="_blank"
      class="button button-outer"
      @click="sendMetrika('aipizza')"
    >AI-пицца</a>
    <br />
  </div>
</template>

<script>
export default {
  methods: {
    sendMetrika(eventId) {
      window.ym.send(eventId, this.$store.devmode ? "dev" : "manager");
    }
  }
};
</script>

<style></style>