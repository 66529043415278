<template>
    <div class=input-wrapper>
        <input ref=inputref :value=value :disabled=disabled
            @input='onPrint($event.target.value)'
            @keydown="keydown($event)"
            @keydown.tab="autocomplete($event)"
            @keyup="updateCaretPosition(); $emit('press', $event);"
            @keyup.enter="$emit('enter')"
            @keyup.down="$emit('arrowdown')"
            @keyup.up="$emit('arrowup')"
            autofocus
            />
        <div class=input-display>
            <span class='char' :class='{caret: caretPos === i}' v-for="(char, i) of value" v-text=char :key=i></span>
            <span class='char caret' v-show='caretPos === -1'>&nbsp;</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        autocompleteList: {
            type: Array,
            default: () => []
        },
        value: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        caretPos: -1
    }),

    computed: {
        suggestion() {
            const command = this.value.toLowerCase().trim();
            if (command === '') return '';
            return this.autocompleteList.find(c => (c.length > command.length) && c.startsWith(command));
        }
    },

    methods: {
        onPrint(value) {
            this.$emit('input', value);
        },
        autocomplete(e) {
            e.preventDefault();
            if (this.suggestion)
                this.$emit('input', this.suggestion);
        },
        updateCaretPosition() {
            if (!this.$refs.inputref) return;
            const { selectionStart, selectionEnd } = this.$refs.inputref;
            if (Math.abs(selectionEnd - selectionStart) > 0)
                this.$refs.inputref.setSelectionRange(selectionStart, selectionStart);
            this.caretPos = selectionStart;
            if (this.caretPos === this.value.length)
                this.caretPos = -1;
        },
        keydown(e) {
            if (e.which === 38) e.preventDefault();
            this.updateCaretPosition();
        },
        globalkeyup(e) {
            if (this.$refs.inputref === document.activeElement) return;
            if (e.key && e.key.length === 1) {
                e.preventDefault();
                this.$emit('input', this.value + e.key);
            }
            this.$refs.inputref.focus();
            this.$refs.inputref.setSelectionRange(this.value.length, this.value.length);
            this.caretPos = -1;
        },
    },

    mounted() {
        document.addEventListener('keyup', this.globalkeyup);
    },

    beforeDestroy() {
        document.removeEventListener('keyup', this.globalkeyup);
    },

}
</script>

<style lang='scss'>
    $main-color: #D1FB84;

    .input-wrapper {
        display: inline-block;
        margin-bottom: 32px;

        > input {
            opacity: 0;
            position: absolute;
        }
    }
    .input-display {
        display: inline-block;
        word-break: break-all;
        white-space: normal;
        overflow: visible;

        > .char {
            position: relative;
            display: inline-block;
            height: 1.25em;
            min-width: 8px;
            margin-bottom: -3px;
            text-align: center;
            cursor: text;

            &:last-child {
                width: .75em;
            }

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                min-width: 10px;
                z-index: 0;
                background-color: $main-color;
                box-shadow: 0px 0px 40px transparent;
                z-index: -1;
                opacity: 0;
            }
        }
        > .caret {
            color: black;
            animation: blink-text 1s steps(2, start) infinite;

            &::before {
                opacity: 1;
                animation: blink-bg 1s steps(2, start) infinite;
            }
        }
    }
    @keyframes blink-bg {
        to {
            visibility: hidden;
        }
    }
    @keyframes blink-text {
        to {
            color: $main-color;
        }
    }
</style>