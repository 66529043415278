<template>
  <div>
    <br>
    <p>Чтобы перейти дальше, введи:</p>
    <p>&gt; {{tip}}</p>
    <br />
    <p>Чтобы вернуться на главную:</p>
    <p>&gt;&nbsp;home</p>
  </div>
</template>

<script>
export default {
  props: {
    tip: {
      type: String,
      default: ""
    }
  }
};
</script>

<style></style>