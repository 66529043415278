import GAME, { random } from './Game.code';
import RetroType from '../../components/RetroType';
import RetroInput from '../../components/RetroInput';

export default {
    components: {
        RetroType,
        RetroInput,
    },
    data: () => ({
        scoreboard: {
            score: 0,
            difficulty: 0,
            snakeLength: 10,
        },
        stats: {
            gameStartedAt: null,
            gameOverAt: null,
            keysPressedCount: 0,
            maxSnakeLength: 0,
        },
        gameEvents: {
            whenGameOver: () => {},
            whenCameback: () => {},
            whenColliedItself: () => {},
            whenRolesSwitch: () => {},
            whenRolesSwitchBack: () => {},
            whenSlowMode: () => {},
            whenMaxLevel: () => {},
        },
        prevBestScore: null,
        customGlobalStyles: null,
        canPlay: true,
        needConfirm: false,
        isPaused: false,
        motivateText: null,
        removeEventHandlers: () => {},
    }),

    computed: {
        timePlayed() {
            const playedSec = Math.floor((this.stats.gameOverAt - this.stats.gameStartedAt) * .001);
            let sec = ('' + playedSec % 60).padStart(2, '0');
            let min = '' + Math.floor((playedSec / 60) % 60);
            let hours = Math.floor(playedSec / 3600);
            if (hours > 0)
                return `${('' + hours).padStart(2, '0')}:${min}:${sec}`
            return min + ':' + sec;
        },
        gameResults() {
            const results = [
                `Your score:   ${this.scoreboard.score}`,
            ];
            if (!!this.prevBestScore && this.prevBestScore < this.scoreboard.score) {
                results[0] += '  NEW RECORD!'
                results.push(`Previous best:  ${this.prevBestScore}`)
            }
            results.push(
                `Goals reached:   ${this.stats.goalsReached}`,
                `Difficulty reached:   ${this.scoreboard.difficulty}`,
                `Max snake length:   ${this.stats.maxSnakeLength.toFixed(1)}`,
                `Key pressed:   ${this.stats.keysPressedCount}`,
                `Time played:   ${this.timePlayed}`
                );
            return results;
        },
        gameResultClassNames() {
            const classNames = [ 'score' ];
            if (!!this.prevBestScore && this.prevBestScore < this.scoreboard.score) {
                classNames[0] += ' with-record';
                classNames.push('prev-record');
            }
            return classNames;
        }
    },

    methods: {
        goToMenu() {
            this.$router.push({ path: '/' });
        },
        playAgain() {
            this.canPlay = true;
            this.$nextTick(() => GAME.startGame(this));
        },
        pauseGame(state = null) {
            if (!this.canPlay) return;
            if (state === null) {
                this.isPaused = !this.isPaused;
                GAME.pauseGame();
                return;
            }
            GAME.pauseGame(state);
            this.isPaused = state;
        },

        bindGameEvents() {
            this.gameEvents.whenGameOver = () => {
                const best = localStorage.getItem('reversnake.bestscore') // 0;
                if (best) this.prevBestScore = best;
                if (best < this.scoreboard.score)
                    localStorage.setItem('reversnake.bestscore', this.scoreboard.score);
                this.canPlay = false;
            }
            this.gameEvents.whenColliedItself = () => this.motivate([
                `LOL !!!`,
                `looks funny!`,
                `ha-ha!`,
                `OMG!`
            ])
            this.gameEvents.whenSlowMode = () => this.motivate([
                `let's chill`,
                `how about that pace?`,
                `plz.. go slower`
            ])
        },

        motivate(text = []) {
            this.motivateText = text[random(0, text.length - 1)];
            setTimeout(() => this.motivateText = null, 3200);
        },
    },

    async mounted() {
        this.bindGameEvents();
        await GAME.startGame(this);
        const setPausedOnBlur = () => this.pauseGame(true);
        window.addEventListener('blur', setPausedOnBlur);
        const pauseByEsc = e => e.which === 27 ? this.pauseGame() : null;
        document.addEventListener('keyup', pauseByEsc);
        this.removeEventHandlers = () => {
            window.removeEventListener('blur', setPausedOnBlur);
            document.removeEventListener('keyup', pauseByEsc);
        }
    },

    beforeDestroy() {
        this.removeEventHandlers();
        GAME.stopGame();
    }
}