const fieldOf = (obj, path) => {
  const parts = path.split('.');
  if (parts.length === 1)
    return obj[path];
  let objPointer = obj;
  for (const part of parts) {
    if (false === part in objPointer)
      return void 0;
    objPointer = objPointer[part];
  }
  return objPointer
}

let inProgress;
let inProgressResolve = () => {};

export default {
  async getJobsList() {
    if (window.jobList)
      return Promise.resolve(window.jobList);
    if (inProgress)
      return inProgress;

    inProgress = new Promise(resolve => inProgressResolve = resolve);

    const { linkFormat } = await window.getLoadedJSON('jobs');
    const jobs = [];

    if (window.whr) {
      window.whr(document).ready(() => {
        try {
          window.whr_embed(313923, {detail: 'titles', base: 'departments', zoom: 'city'});
          window.whrcallback = (data) => {
            if (!data || !data.jobs) {
              return inProgressResolve(jobs);
            }
            jobs.length = 0;
            data.jobs.forEach(j => {
              if (!j || j.department !== 'IT') return;
              j.href = linkFormat.replace(
                /(\{([\w\d_-]+)\})/ig,
                (_fullMatch, _replacement, field) => fieldOf(j, field),
              );
              jobs.push(j);
            });
            inProgressResolve(jobs)
          }
        } catch (e) {
          /** ignore errors */
        }
      });
    }

    await inProgress;

    window.jobList = jobs;
    return jobs;
  }
}