<template>
  <div>
    <!-- <a href="#" class="button button-outer job job-show" style="pointer-events: none;">
      <span>Раздел с вакансиями в данный момент дорабатывается<br><br>¯\_(ツ)_/¯</span>
    </a> -->
    <a
      v-for="job in jobs"
      :key="'jobid_' + job.id"
      :href="job.url"
      :ref="`job_${job.id}`"
      target="_blank"
      class="button button-outer job"
      @click="sendMetrika(job)"
    >
      <span v-text="job.title"></span>
    </a>
  </div>
</template>

<script>
// import jobService from '../services/jobs.service'
/* eslint-disable no-irregular-whitespace */

const titleSort = (j1, j2) => {
  j1 = j1.title.toUpperCase();
  j2 = j2.title.toUpperCase();

  return (j1 > j2) ? 1
    : (j1 < j2) ? -1
    : 0;
}

export default {
  data: () => ({
    jobs: [],
  }),

  methods: {
    sendMetrika({ id, title }) {
      window.ym.send('vacancy_click', null, { id, title });
    },
  },

  async mounted() {
    this.$set(this, 'jobs', await window.getLoadedJSON('jobs'));

    const showFn = el => () => el.classList.add('job-show')
    this.$nextTick(() => setTimeout(() =>
      Object.values(this.$refs).forEach(([ item ], i) =>
        setTimeout(showFn(item), 50 * i)
      )
    ), 2000);
  }
};
</script>

<style lang="scss">
  .job {
    display: none;

    &-show {
      display: block;
    }

    > span {
      display: block;
    }
  }
</style>