import { render, staticRenderFns } from "./Game.vue?vue&type=template&id=f12f11b8&scoped=true&"
import script from "./Game.js?vue&type=script&lang=js&"
export * from "./Game.js?vue&type=script&lang=js&"
import style0 from "./Game.scss?vue&type=style&index=0&id=f12f11b8&prod&lang=scss&scoped=true&"
import style1 from "./Game.vue?vue&type=style&index=1&id=f12f11b8&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f12f11b8",
  null
  
)

export default component.exports