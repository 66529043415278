<template>
  <div>
    <a
      v-for="(link, index) of links"
      v-text="link.title"
      :key="index"
      :href="link.href"
      target="_blank"
      class="button button-inline"
      @click="sendMetrika(link.eventId)"
    ></a>
    <br>
  </div>
</template>

<script>
export default {
  data: () => ({
    links: []
  }),

  methods: {
    sendMetrika(eventId) {
      window.ym.send(eventId, this.$store.devmode ? "dev" : "manager");
    }
  },

  async mounted() {
    const links = await window.getLoadedJSON('socialLinks');

    [...Object.values(links)]
      .filter(l => l.title)
      .forEach(l => this.links.push(l))
  }
};
</script>

<style></style>