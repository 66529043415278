export const random = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

// set DEBUG = false, if u want to play normally
// const DEBUG = process.env.NODE_ENV !== 'development' ? false
//     : {
//         SHOULD_UPDATE_DIFFICULTY: false,
//         SHOULD_DIE_SNAKE: false,
//         OVERRIDE_DIE_INTERVAL: false,
//         OVERRIDE_FAST_MOVE_CHANCE: 60,
//         OVERRIDE_SNAKE_MOVE_INTERVAL: 50
//     };
const DEBUG = false;

const INNER_MARGIN = 0
const CANVAS_WIDTH = 650
const CANVAS_HEIGHT = 400

const CELL_WIDTH = 10;
const CELL_HEIGHT = 10;
const CELL_GAP = 1;

const FOOD_PART_WIDTH = 16;
const FOOD_PART_HEIGHT = 16;

const SNAKE_MOVE_INTERVAL = Math.round(1000 / 5)  // 5 frames per second for snake move (lower = harder, higher = easier)
const FOOD_MOVE_INTERVAL  = Math.round(1000 / 10) // 10 frames per second for food move (higher = harder, lower = easier)
const SNAKE_CELL_DIE_INTERVAL = 4000 // last cell will die after every 4 sec

const FOOD_CREATE_DELAY = 200
const FOOD_HEAL_IF_LESS = .6 // eating of food will heal last cell of the snake
                             // instead of increasing its length
                             // when last body-part has health LESS than this value

const INITIAL_SNAKE_LENGTH = 10

const SCORE_RAISE_STEP = 25

const DIFFICULTIES = [
    {   scoreNeed: 0,
        fn: () => { /* DIFFICULTY: 0 */
            _.timings.snakeMove = SNAKE_MOVE_INTERVAL;
            _.timings.snakeDieAfter = SNAKE_CELL_DIE_INTERVAL;
            _.timings.userControl = FOOD_MOVE_INTERVAL;
            _.reversedRoles = true; // REMOVE
        }
    },
    {   scoreNeed: /*400*/ SCORE_RAISE_STEP * 16,
        fn: () => { /* DIFFICULTY: 1 */
            _.timings.snakeMove = SNAKE_MOVE_INTERVAL / 1.5;
            _.timings.userControl = FOOD_MOVE_INTERVAL * 1.1;
            _.timings.snakeDieAfter = SNAKE_CELL_DIE_INTERVAL * .8;
            _.AI.fastMoveChance = 30;
        }
    },
    {   scoreNeed: /*900*/ SCORE_RAISE_STEP * 36,
        fn: () => { /* DIFFICULTY: 2 */
            _.timings.snakeMove = SNAKE_MOVE_INTERVAL / 1.9;
            _.timings.userControl = FOOD_MOVE_INTERVAL;
            _.timings.snakeDieAfter = SNAKE_CELL_DIE_INTERVAL * .6;
        }
    },
    {   scoreNeed: /*1500*/ SCORE_RAISE_STEP * 60,
        fn: () => { /* DIFFICULTY: 3 */
            _.timings.snakeMove = SNAKE_MOVE_INTERVAL / 2;
            _.timings.userControl = FOOD_MOVE_INTERVAL * 1.2;
            _.timings.snakeDieAfter = SNAKE_CELL_DIE_INTERVAL * .4;
            ø.food.onlyHealThreshold = .5;
            ø.snake.youth = 1;
        }
    },
    {   scoreNeed: /*2000*/ SCORE_RAISE_STEP * 80,
        fn: () => { /* DIFFICULTY: 4 */
            _.timings.snakeMove = SNAKE_MOVE_INTERVAL / 2.5;
            _.timings.userControl = FOOD_MOVE_INTERVAL;
            _.timings.snakeDieAfter = SNAKE_CELL_DIE_INTERVAL * .6;
            ø.snake.youth = 1;
            ø.snake.gains += 4;
        }
    },
    {   scoreNeed: /*4000*/ SCORE_RAISE_STEP * 160,
        fn: () => { /* DIFFICULTY: 5 */
            _.timings.snakeMove = SNAKE_MOVE_INTERVAL / 3.5;
            _.timings.userControl = FOOD_MOVE_INTERVAL * .8;
            _.timings.snakeDieAfter = SNAKE_CELL_DIE_INTERVAL * .5;
            ø.food.onlyHealThreshold = .3;
            ø.snake.youth = 1;
            ø.snake.gains += 6;
        }
    },
    {   scoreNeed: /*6000*/ SCORE_RAISE_STEP * 240,
        fn: () => { /* DIFFICULTY: 6 */
            _.timings.snakeMove = SNAKE_MOVE_INTERVAL / 5;
            _.timings.userControl = FOOD_MOVE_INTERVAL * .4;
            _.timings.snakeDieAfter = SNAKE_CELL_DIE_INTERVAL * .4;
            ø.food.onlyHealThreshold = .5;
            ø.snake.youth = 1;
            ø.snake.gains += 10;
        }
    },
    {   scoreNeed: /*8000*/ SCORE_RAISE_STEP * 320,
        fn: () => { /* DIFFICULTY: 7 */
            _.gameEvents.whenSlowMode();
            _.timings.snakeMove = SNAKE_MOVE_INTERVAL / 1.8;
            _.timings.userControl = FOOD_MOVE_INTERVAL * 1.2;
            _.timings.snakeDieAfter = SNAKE_CELL_DIE_INTERVAL * .7;
            _.AI.fastMoveChance = 60;
            ø.food.onlyHealThreshold = .8;
            ø.snake.youth = 1;
            ø.snake.gains += 4;
        }
    },
    {   scoreNeed: /*10000*/ SCORE_RAISE_STEP * 400,
        fn: () => { /* DIFFICULTY: 8 */
            _.timings.snakeMove = SNAKE_MOVE_INTERVAL / 1.8;
            _.timings.userControl = FOOD_MOVE_INTERVAL * 1.3;
            _.timings.snakeDieAfter = SNAKE_CELL_DIE_INTERVAL * .5;
            _.AI.fastMoveChance = 60;
            ø.food.onlyHealThreshold = .4;
            ø.snake.youth = 1;
            ø.snake.gains += 5;
        }
    }
];

// #region GAME DATA
const $ = {
    canvas: null,
    ctx: null,
    bounds: {
        w: null,
        h: null
    },
};
const _ = {
    game: {
        difficulty: 0,
        isStopped: false,
        isPaused: false,
        reversedRoles: false,
    },
    AI: {
        prevExecTime: Math.floor(+new Date / 1000),
        nextExecAfter: random(1, 3),
        fastMoveChance: 20,
    },
    timings: {
        snakeMove: SNAKE_MOVE_INTERVAL,
        snakeDieAfter: SNAKE_CELL_DIE_INTERVAL,
        userControl: FOOD_MOVE_INTERVAL,
    },
    scoreboard: {
        score: 0,
        difficulty: 0,
        snakeLength: 10,
    },
    stats: {
        gameStartedAt: null,
        keysPressedCount: 0,
        maxSnakeLength: 0,
        goalsReached: 0,
    },
    gameEvents: {
        whenGameOver: () => {},
        whenCameback: () => {},
        whenColliedItself: () => {},
        whenRolesSwitch: () => {},
        whenRolesSwitchBack: () => {},
        whenSlowMode: () => {},
    },
};
const ø = {
    snake: {
        size: { w: CELL_WIDTH, h: CELL_HEIGHT, gap: CELL_GAP },
        velocity: { x: 0, y: -1 },
        youth: 1,
        gains: 0,
        length: INITIAL_SNAKE_LENGTH,
        body: (() => {
            const b = [ ];
            b.add = b.unshift;
            b.reset = () => {
                b.splice(0);
                for (;b.length <= INITIAL_SNAKE_LENGTH;)
                    b.push({ x: 0, y: -(INITIAL_SNAKE_LENGTH - b.length + 2) })
            };
            b.head = () => b[0];
            b.ass = () => b[Math.max(0, b.length - 1)];
            b.fromEnd = (i) => (i >= b.length) ? undefined : b[Math.max(0, b.length - i - 1)];
            b.removeLast = () => b.splice(b.length - 1);
            b.raise = () => b.push(b.ass());
            b.reset();
            return b;
        })(),
        corpse: [],
    },
    food: (() => {
        const f = {
            parts: [ 1, 1, 1, 1 ],
            prev: { x: -1, y: -1 },
            pos: { x: 0, y: 0 },
        };
        f.onlyHealThreshold = FOOD_HEAL_IF_LESS;
        f.imageSrc = null;
        f.eaten = () => f.parts.every(i => i === 0);
        f.reset = () => Object.keys(f.parts).forEach(k => f.parts[k] = 1);
        f.collides = () => { /* should be set IN drawFood fn-fabric */ }
        return f;
    })(),
};
// #endregion

// #region ENGINE
    const waitNextDraw = () => {
        requestAnimationFrame(drawframe);
    };
    let nextEngineFrameId = null;
    const waitNextEngine = (() => {
        if (DEBUG && typeof DEBUG.OVERRIDE_SNAKE_MOVE_INTERVAL === 'number') {
            const interval = DEBUG.OVERRIDE_SNAKE_MOVE_INTERVAL;
            return () => {
                if (nextEngineFrameId)
                    nextEngineFrameId = clearTimeout(nextEngineFrameId);
                nextEngineFrameId = setTimeout(engineframe, interval * .1);
            };
        }
        return () => {
            if (nextEngineFrameId)
                nextEngineFrameId = clearTimeout(nextEngineFrameId);
            nextEngineFrameId = setTimeout(engineframe, _.timings.snakeMove * .1);
        }
    })();
    const drawframe = (() => {
        let i = 0;
        let _on_10th = (action) => i === 10 ? action() : 0;
        return () => {
            _on_10th(drawKilledSnakePart);
            drawFood();
            drawSnake();

            if (i++ === 10) i = 0;
            if (!_.game.isStopped && !_.game.isPaused)
                waitNextDraw();
        }
    })();
    const engineframe = (() => {
        let i = 0;
        let _on_10th = (action) => i === 10 ? action() : 0;
        return () => {
            aiSnake();
            _on_10th(moveSnake);
            collideFood();
            _on_10th(gainSnake);
            getOldSnake();

            if (i++ === 10) i = 0;
            if (!_.game.isStopped && !_.game.isPaused)
                waitNextEngine();
        }
    })();
// #endregion

// #region STATS / DIFFICULTY
    const updateDifficulty = () => {
        const score = _.scoreboard.score;
        let index = DIFFICULTIES.findIndex(d => d.scoreNeed > score);
        if (index === -1)
            index = DIFFICULTIES.length;
        index = Math.max(0, index - 1);
        const currentDiff = _.game.difficulty === 'MAX'
            ? DIFFICULTIES.length - 1
            : _.game.difficulty;
        if (index === currentDiff) return
        _.scoreboard.difficulty = index !== (DIFFICULTIES.length - 1) ? index : 'MAX';
        if (_.scoreboard.difficulty === 'MAX')
            _.gameEvents.whenMaxLevel();
        _.game.difficulty = index;
        _.reversedRoles = undefined;
        DIFFICULTIES[index].fn();
        _.timings.snakeMove = Math.floor(_.timings.snakeMove);
        _.timings.snakeDieAfter = Math.floor(_.timings.snakeDieAfter);
        _.timings.userControl = Math.floor(_.timings.userControl);
        _.reversedRoles = true === _.reversedRoles; // false by default
    }
    const checkMaxSnakeLength = () => {
        if (_.stats.maxSnakeLength < _.scoreboard.snakeLength)
            _.stats.maxSnakeLength = _.scoreboard.snakeLength;
    }
    const raiseScore = (() => {
        const updDifficulty = !DEBUG || DEBUG.SHOULD_UPDATE_DIFFICULTY
            ? updateDifficulty
            : () => {};
        return ({ multiplier = 1 } = {}) => {
            _.scoreboard.score += parseInt((SCORE_RAISE_STEP * (1 + _.game.difficulty * .5) * multiplier).toFixed(0));
            updDifficulty();
        }
    })();
// #endregion

// #region AI
    const aiSnake = (() => {
        const velocities = [
            { x: -1, y:  0 },
            { x: +1, y:  0 },
            { x:  0, y: +1 },
            { x:  0, y: -1 },
        ];
        let fastMoveChanceDebug = null;
        if (DEBUG && typeof DEBUG.OVERRIDE_FAST_MOVE_CHANCE === 'number')
            fastMoveChanceDebug = DEBUG.OVERRIDE_FAST_MOVE_CHANCE;
        return () => {
            if (_.game.reversedRoles) return;
            const now = Math.floor(+new Date / 1000);
            if (_.AI.prevExecTime + _.AI.nextExecAfter > now) return;
            const isFastMove = fastMoveChanceDebug === null
                ? random(0, 100) <= _.AI.fastMoveChance
                : random(0, 100) <= fastMoveChanceDebug;
            _.AI.prevExecTime = now;
            _.AI.nextExecAfter = isFastMove ? random(1, 2) : random(3, 5);
            let v = ø.snake.velocity;
            let oldv = ø.snake.velocity || { x: 0, y: 0 };
            while (v === null
                || v === oldv
                || (v.x + oldv.x === 0 && v.y + oldv.y === 0)
                )
                v = velocities[random(0, 3)];
            ø.snake.velocity = v;
        };
    })();
    const aiFood = () => {
        
    };
// #endregion

// #region SNAKE LIFECYCLE
const gainSnake = () => {
    if (!ø.snake.gains) return;
    while (ø.snake.gains > 0) {
        if (ø.snake.youth >= ø.food.onlyHealThreshold)
            ø.snake.body.raise();
        ø.snake.youth = 1;
        ø.snake.gains--;
    }
    _.scoreboard.snakeLength = ø.snake.body.length - 2 + ø.snake.youth;
    checkMaxSnakeLength();
}
/** decreasing of snake length */
const getOldSnake = (() => {
    if (DEBUG && !DEBUG.SHOULD_DIE_SNAKE)
        return () => {};

    let dieTimingOverride = DEBUG && DEBUG.OVERRIDE_DIE_INTERVAL
        ? DEBUG.OVERRIDE_DIE_INTERVAL
        : false;
    let lastAging = +new Date;
    return () => {
        const now = +new Date;
        const dieAfter = dieTimingOverride || _.timings.snakeDieAfter;
        if (lastAging + (dieAfter * .1) > now) return;
        lastAging = now;
        let youth = ø.snake.youth;
        if (youth >= .1)
            youth -= .1;
        else
            youth = 0;
        ø.snake.youth = Math.floor(10 * youth) * .1;
        _.scoreboard.snakeLength = ø.snake.body.length - 2 + youth;
        checkMaxSnakeLength();
    }
})();
/** remove part of the snake when it collides with itself */
const killSnakePart = (bodyIndex) => {
    const corpseAdd = ø.snake.body
        .splice(bodyIndex)
        .map(i => ({ ...i, alpha: 2 }));
    Array.prototype.push.apply(
        ø.snake.corpse,
        corpseAdd
        );
    ø.snake.corpse.shift();
    _.scoreboard.snakeLength = ø.snake.body.length - 2 + ø.snake.youth;
    if (corpseAdd.length > 2)
        _.gameEvents.whenColliedItself();
}
// #endregion

// #region MOVING
const moveSnake = () => {
    const s = ø.snake;
    const b = $.bounds;
    const v = s.velocity;
    const h = s.body.head();

    let x = h.x + v.x;
    if (x > (b.w - 1)) x = 0;
    else if (x < 0) x = b.w - 1;

    let y = h.y + v.y;
    if (y > (b.h - 1)) y = 0;
    else if (y < 0) y = b.h - 1;

    const newHead = { x, y };
    s.body.add(newHead);
    s.body.removeLast();
    const newAss = s.body.ass();

    if (s.youth < 0.1) {
        if (s.body.length === 2) {
            gameShouldOver();
            return;
        }
        s.body.removeLast();
        s.youth = 1;
    }

    const intersectionIndex = s.body.findIndex(c =>
        c !== newHead && c !== newAss
        && c.x === x && c.y === y);
    if (intersectionIndex > -1)
        killSnakePart(intersectionIndex);
};
const moveFood = (directions) => {
    const { pos } = ø.food
    const b = $.bounds;
    ø.food.prev = { ...pos };
    for (const d of directions) {
        switch (d) {
            case 'left': pos.x--; break;
            case 'right': pos.x++; break;
            case 'top': pos.y--; break;
            case 'bottom': pos.y++; break;
        }
    }
    if (pos.x < 0) pos.x = b.w - 2;
    if (pos.y < 0) pos.y = b.h - 2;
    if (pos.x > (b.w - 2)) pos.x = 0;
    if (pos.y > (b.h - 2)) pos.y = 0;
    clearPrevFood();
    drawFood();
}
let clearPrevFood = () => {}
// #endregion

// #region DRAWING
const drawSnake = () => {
    if (!$.ctx) return;
    const s = ø.snake;
    const { body, size, youth } = s;
    const { w, h, gap: g } = size;
    const ass = body.ass();
    const wg = (w + g);
    const hg = (h + g);
    $.ctx.fillStyle = "#D1FB84";
    for (const cell of body)
        $.ctx.fillRect(cell.x * wg, cell.y * hg, w, h);
    $.ctx.clearRect(ass.x * wg, ass.y * hg, w, h);
    if (youth === 1) return;
    const last = body.fromEnd(1);
    if (!last) return;
    let alpha = Math.max(0, youth - .2);
    $.ctx.fillStyle = `rgba(197, 254, 113, ${alpha})`
    $.ctx.clearRect(last.x * wg, last.y * hg, w, h);
    $.ctx.fillRect(last.x * wg, last.y * hg, w, h);
    if (youth > .7) return;
    const prelast = body.fromEnd(2);
    if (!prelast) return
    alpha = Math.min(1, .7 + youth * .5);
    $.ctx.fillStyle = `rgba(197, 254, 113, ${alpha.toFixed(2)})`
    $.ctx.clearRect(prelast.x * wg, prelast.y * hg, w, h);
    $.ctx.fillRect(prelast.x * wg, prelast.y * hg, w, h);
};
const drawKilledSnakePart = () => {
    if (ø.snake.corpse.length === 0 || !$.ctx) return;
    const { w, h, gap: g } = ø.snake.size;
    const [ wg, hg ] = [ w+g, h+g ];
    const { corpse } = ø.snake;
    let diff = .55;
    for (let i = corpse.length - 1; i >= 0; i--) {
        const c = corpse[i];
        if (!c || !c.alpha) continue;
        diff = parseFloat((diff - .03).toFixed(2));
        if (diff <= 0) break;
        c.alpha = Math.max(0, Math.min(.7, parseFloat((c.alpha - diff).toFixed(2))));
        $.ctx.clearRect(c.x * wg, c.y * hg, w, h);
        if (c.alpha === 0) continue;
        $.ctx.fillStyle = `rgba(197, 254, 113, ${c.alpha})`
        $.ctx.fillRect(c.x * wg, c.y * hg, w, h);
    }
    ø.snake.corpse = corpse.filter(c => c.alpha > 0);
}
const drawFood = (() => {
    const fw = FOOD_PART_WIDTH;
    const fh = FOOD_PART_HEIGHT;
    const cw = CELL_WIDTH + CELL_GAP;
    const ch = CELL_HEIGHT + CELL_GAP;
    const g = CELL_GAP;
    const fcw = Math.round(.5 * (fw - CELL_WIDTH - CELL_GAP));
    const fch = Math.round(.5 * (fh - CELL_HEIGHT - CELL_GAP));
    ø.food.collides = () => {
        const { pos } = ø.food;
        const spos = ø.snake.body.head();
        const head = {
            x1: Math.floor(spos.x * cw),
            y1: Math.floor(spos.y * ch),
            x2: Math.floor(spos.x * cw + cw),
            y2: Math.floor(spos.y * ch + ch),
        };
        const isit = ([ x, y, w, h ]) => {
            const boiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii = ([ px, py ]) =>
                   px >= x && px <= (x + w)
                && py >= y && py <= (y + h);
            return boiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii([ head.x1, head.y1 ])
                || boiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii([ head.x1, head.y2 ])
                || boiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii([ head.x2, head.y1 ])
                || boiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii([ head.x2, head.y2 ])
        }
        return [
            isit([ pos.x * cw - fcw, pos.y * ch - fch, fw + g, fh + g ]),
            isit([ pos.x * cw + cw + fcw, pos.y * ch - fch, fw + g, fh + g ]),
            isit([ pos.x * cw - fcw, pos.y * ch + ch + fch, fw + g, fh + g ]),
            isit([ pos.x * cw + cw + fcw, pos.y * ch + ch + fcw, fw + g, fh + g ]),
        ]
        .map(x => x ? 1 : 0);
    }
    clearPrevFood = () => {
        const { prev } = ø.food;
        $.ctx.clearRect(prev.x * cw - fw, prev.y * ch - fh, fw * 4, fh * 4);
    }
    return () => {
        const { parts, pos } = ø.food;
        if (!$.ctx) return;
        clearPrevFood();
        if (parts.every(p => !p)) return;
        $.ctx.drawImage(ø.food.imageSrc, pos.x * cw - fcw, pos.y * ch - fch);
        if (!parts[0]) $.ctx.clearRect(pos.x * cw - fcw, pos.y * ch - fch, fw + g, fh + g);
        if (!parts[1]) $.ctx.clearRect(pos.x * cw + cw + fcw, pos.y * ch - fch, fw + g, fh + g);
        if (!parts[2]) $.ctx.clearRect(pos.x * cw - fcw, pos.y * ch + ch + fch, fw + g, fh + g);
        if (!parts[3]) $.ctx.clearRect(pos.x * cw + cw + fcw, pos.y * ch + ch + fcw, fw + g, fh + g);
    }
})();
// #endregion

// #region FOOD LIFECYCLE
const collideFood = () => {
    const { parts } = ø.food;
    const lastcollisions = parts.map(p => p ? 0 : 1).join('');
    const collisions = ø.food.collides();
    if (lastcollisions === collisions.join('')) return;
    let gained = 0;
    Object.keys(collisions)
        .forEach(i => {
            if (parts[i] === 0 || !collisions[i]) return;
            ø.food.parts[i] = 0;
            gained++;
        });
    if (gained === 0) return;
    _.stats.goalsReached += +(gained * .25).toFixed(2)
    ø.snake.gains += gained;
    const allEaten = ø.food.eaten();
    raiseScore({ multiplier: allEaten ? (gained + 5) : gained });
    if (allEaten) {
        ø.food.prev = { ...ø.food.pos };
        setTimeout(newFood, FOOD_CREATE_DELAY);
    }
}
const newFood = () => {
    let point = null;
    const { x: hx, y: hy } = ø.snake.body.head();
    const canBePlaced = p =>
        p.x !== hx && p.y !== hy && p.x !== hx+1 && p.y !== hy+1;
    while (!point || !canBePlaced(point)) {
        point = {
            x: random(1, $.bounds.w - 3),
            y: random(1, $.bounds.h - 3)
        }
    }
    ø.food.pos = { ...point };
    ø.food.reset();
}
// #endregion

// #region CONTROLS
let deattachControls = () => {};
const attachControls = () => {
    let timeoutId = null;
    let movingTo = [];
    const [ w, a, s, d ] = [87, 65, 83, 68];
    const [ t, l, b, r ] = [38, 37, 40, 39]; // arrow keys (top, left, right, bottom)
    const keyDown = ({ which }) => {
        const nextMove = () => {
            timeoutId = setTimeout(() => {
                moveFood(movingTo);
                if (timeoutId) nextMove();
            }, _.timings.userControl);
        }
        const ok = dir => {
            if (!movingTo.includes(dir)) movingTo.push(dir);
            if (!timeoutId) nextMove();
        }
        switch (which) {
            case t:
            case w: return ok('top');
            case l:
            case a: return ok('left');
            case b:
            case s: return ok('bottom');
            case r:
            case d: return ok('right');

            // add 20 gains on B-key (REMOVE this)
            // case 66: return ø.snake.gains = 20;
        }
    };
    const keyUp = ({ which }) => {
        const ok = dir => {
            _.stats.keysPressedCount++;
            movingTo = movingTo.filter(d => d !== dir);
            if (movingTo.length === 0 && timeoutId) {
                clearTimeout(timeoutId);
                timeoutId = null;
            }
        }
        switch (which) {
            case t:
            case w: return ok('top');
            case l:
            case a: return ok('left');
            case b:
            case s: return ok('bottom');
            case r:
            case d: return ok('right');
        }
    };
    document.addEventListener('keydown', keyDown);
    document.addEventListener('keyup', keyUp);
    deattachControls = () => {
        document.removeEventListener('keydown', keyDown);
        document.removeEventListener('keyup', keyUp);
        if (timeoutId) timeoutId = clearTimeout(timeoutId);
    }
};
const sliceImgLoad = () => {
    let resolve = () => {}
    const svgPath = require('../../assets/svg/pizza-slice-32.svg');
    const image = new Image();
    image.width = FOOD_PART_WIDTH * 2;
    image.height = FOOD_PART_HEIGHT * 2;
    image.onload = () => {
        ø.food.imageSrc = image;
        resolve();
    }
    image.onerror = () => resolve();
    image.src = svgPath;
    return new Promise(r => resolve = r);
};
const gameShouldOver = () => {
    _.game.isStopped = true;
    _.stats.gameOverAt = +(new Date);
    _.gameEvents.whenGameOver();
    deattachControls();
}
// #endregion

// #region INIT / EXPORTS
const patchCanvasContext = (ctx) => {
    if (ctx.patched) return ctx;
    ctx.imageSmoothingEnabled =
    ctx.mozImageSmoothingEnabled =
    ctx.msImageSmoothingEnabled =
    ctx.webkitImageSmoothingEnabled = false;
    const { fillRect, clearRect, drawImage } = ctx;
    ctx.fillRect = function (...args) {
        args[0] += INNER_MARGIN;
        args[1] += INNER_MARGIN;
        return fillRect.apply(this, args);
    }
    ctx.clearRect = function (...args) {
        args[0] += INNER_MARGIN;
        args[1] += INNER_MARGIN;
        return clearRect.apply(this, args);
    }
    ctx.drawImage = function (...args) {
        args[1] += INNER_MARGIN;
        args[2] += INNER_MARGIN;
        return drawImage.apply(this, args);
    }
    ctx.patched = true;
    return ctx;
}
const startGame = async (vueContext) => {
    await init(vueContext);
    _.scoreboard.score = 0;
    _.scoreboard.snakeLength = INITIAL_SNAKE_LENGTH;
    ø.snake.body.reset();
    _.game.isStopped = false;
    _.game.isPaused = false;
    _.stats.gameStartedAt = +(new Date);
    _.stats.keysPressedCount = 0;
    _.stats.maxSnakeLength = INITIAL_SNAKE_LENGTH;
    _.stats.goalsReached = 0;
    attachControls();
    updateDifficulty();
    newFood();
    waitNextDraw();
    waitNextEngine();
}
const stopGame = () => {
    _.game.isStopped = true;
    const canvasEl = document.getElementById('playground');
    if (canvasEl) canvasEl.remove();
    $.canvas = null;
    $.ctx = null;
    deattachControls();
}
const pauseGame = (state = null) => {
    if (_.game.isPaused === state) return;
    _.game.isPaused = state === null ? !_.game.isPaused : !!state;
    if (_.game.isPaused) {
        deattachControls();
        return;
    }
    attachControls();
    waitNextDraw();
    waitNextEngine();
}
const init = async ({ scoreboard, stats, gameEvents }) => {
    const canvas = document.createElement('canvas');
    $.canvas = canvas;
    document.getElementById('game').appendChild(canvas);
    canvas.style.margin = '-' + INNER_MARGIN + 'px';
    canvas.setAttribute('id', 'playground');
    canvas.setAttribute('tabindex', '-1');
    canvas.setAttribute('width', CANVAS_WIDTH + INNER_MARGIN * 2);
    canvas.setAttribute('height', CANVAS_HEIGHT + INNER_MARGIN * 2);
    const ctx = patchCanvasContext(canvas.getContext("2d"));
    $.ctx = ctx;
    $.bounds.w = Math.round(CANVAS_WIDTH / (CELL_WIDTH + CELL_GAP));
    $.bounds.h = Math.round(CANVAS_HEIGHT / (CELL_HEIGHT + CELL_GAP));
    _.scoreboard = scoreboard;
    _.stats = stats;
    _.gameEvents = gameEvents;
    await sliceImgLoad();
    $.canvas.focus();
}
// #endregion

export default { startGame, stopGame, pauseGame }