/* eslint-disable no-console */
import Content from './components/Content.vue'
import jobsService from './services/jobs.service';
import publicDataService from './services/public-data.service';

export default {
  name: 'app',
  components: {
    Content,
  },

  data() {
    return {
      charDelay: 4,
      isSongMuted: true,
      isPowering: false,
      isLoading: false,
      isLoaded: false,
      isManagerMode: false,
      isPrinted: false,
    }
  },

  computed: {
  },

  methods: {
    sendMetrika(eventId) {
      window.ym.send(eventId);
    },

    songControl() {
      this.sendMetrika('voice');
      this.isSongMuted = !this.isSongMuted;

      const song = document.getElementById("audio");

      if (this.isSongMuted) song.pause()
      else song.play();
    },

    powerOn() {
      this.isPowering = true;

      setTimeout(() => {
        if (!this.$refs.effects) return;
        this.$refs.effects.style.opacity = ""
        this.$refs.display.style.background = ""
      }, 1500);

      setTimeout(() => {
        this.isPowering = false;
        this.isLoading = true;
      }, 2500);
    },

    toggleMode() {
      if (this.$route.name === 'manager') {
        this.$router.push({ name: 'commandsEmpty' })
        this.$store.devmode = true;
        this.isManagerMode = false;
        this.sendMetrika('managermodetodev');
      }
      else {
        this.$router.push({ name: 'manager' })
        this.$store.devmode = false;
        this.isManagerMode = true;
        this.sendMetrika('devtomanagermode');
      }
    },

    isPrintDone() {
      setTimeout(() => {
        this.isLoading = false;
        this.isLoaded = true;
      }, 2000);
    },
  },

  watch: {
    '$route'(to, from) {
      this.$store.prevUrl = from.fullPath

      if (to.name !== 'manager')
        window.ym.hit(to.fullPath)

      if (from.name !== 'manager')
        return;

      if (this.$route.name === 'manager')
        this.isManagerMode = true;

      setTimeout(window.onresize, 100);
      // this.powerOn();
    }
  },

  mounted() {
    // preload jobs list early
    setTimeout(jobsService.getJobsList, 100);

    if(!window.countriesCount || !window.totalCount) {
      publicDataService.getPublicData().then(({countriesCount, totalCount}) => {
        window.countriesCount = countriesCount
        window.totalCount = totalCount
      })
    }

    if (process.env.NODE_ENV === 'development' && !window.ym.isDebug) {
      let ymOriginSend = window.ym.send;
      let ymOriginHit = window.ym.hit;
      let ymOrigin = window.ym;
      window.ym = function () {
        console.info('yandex.metrika sent:', arguments);
        return ymOrigin.apply(this, arguments);
      }
      window.ym.send = ymOriginSend;
      window.ym.hit = ymOriginHit;
      window.ym.isDebug = true;
    }

    setTimeout(() => {
      this.powerOn();
    }, 1500);

    const path = localStorage.getItem('path');
    if (path) {
      localStorage.removeItem('path');
      this.$router.push({ path });
    }

    if (this.$route.name === 'manager') {
      this.isManagerMode = true;
    }
    // else {
    //   this.isLoaded = true;
    //   this.$refs.effects.style.opacity = "1"
    //   this.$refs.display.style.background = ""
    // }
  }
}