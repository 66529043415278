/* eslint-disable no-console */
import Content from '../../components/Content.vue'
import Jobs from '../../components/Jobs.vue'
import SocialLinks from '../../components/SocialLinks.vue'
import SideProjects from '../../components/SideProjects.vue'
import LinesExtended from "../../content/lines.extended"
import templateService from "../../services/template.service"

const commandFromUrlHash = () => {
  if (!document.location.hash) return null;
  return document.location.hash.substr(1).trim().toLocaleLowerCase();
}
const isHrefHashWithCommand = () => {
  const command = commandFromUrlHash();
  return [
    'home', 'stack', 'projects', 'business', 'jobs',
    'test', 'help', 'ping', 'cowsay', 'pizza', 'game',
    'sudo', 'rm'
  ].some(c => c == command);
}

export default {
  name: 'app',
  components: {
    Content,
    Jobs,
    SocialLinks,
    SideProjects
  },

  data() {
    return {
      lines: { ...LinesExtended },
    };
  },

  methods: {
    sendMetrika(eventId) {
      window.ym.send(eventId);
    },
  },

  async mounted() {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile)
      this.$router.push({
        name: 'command',
        params: {
          command: commandFromUrlHash() || 'home',
        },
      });
    else if (isHrefHashWithCommand()) {
      const hrefHash = document.location.hash;
      document.location.hash = '';
      setTimeout(() => document.location.hash = hrefHash, 100);
    }
    const textLines = await window.getLoadedJSON('textLines');
    Object.keys(textLines).forEach(key =>
      this.$set(this.lines, key, textLines[key].map(text => templateService.replace(text)))
    );
  }
}