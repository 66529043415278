export default {
    replace(text) {
        function innerReplace(text, patchString, replaceString) {
            if (!text.includes(patchString))
                return text;

            return text.replace(patchString, replaceString);
        }

        text = innerReplace(text, '%countries-count%', window.countriesCount || '16')
        text = innerReplace(text, '%stores-count%', window.totalCount || '870+')
        return text;
    }
}