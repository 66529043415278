/**
 * @typedef {{ stop: () => void }} Stoppable
 *
 * @typedef WithAcceptorExtension @type {object}
 * @property {(acceptor: (newLines: string[]) => void) => Stoppable=} acceptLines
 *
 * @typedef WithArgsExtension @type {object}
 * @property {(args: string[]) => void} applyArgs
 *
 * @typedef {WithArgsExtension & WithAcceptorExtension} LinesExtension
 *
 * @typedef {string[] & LinesExtension} LinesExtended
 * @property {() => LinesExtended} copy
 */

/** @returns {LinesExtended} */
const extended = (initLines, extensions) => {
    /** @type {LinesExtended} */
    const l = [...initLines];
    l.copy = () => extended([ ...initLines ], extensions);

    let appliedArgs = [];
    if (extensions.withAcceptor)
        l.acceptLines = (acceptor) => {
            const subscription = extensions.withAcceptor(acceptor, [ ...l ], appliedArgs);
            if (!subscription.stop)
                subscription.stop = () => {};
            return subscription;
        }

    if (extensions.withArgs)
        l.applyArgs = (args) => {
            appliedArgs = args
            return extensions.withArgs(l, args);
        }

    return l;
}
const random = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

export default {
    rm: extended(["D:"], {
        withArgs(lines, args) {
            if (args && (args[0] == '-rf' || args[0] == '-r') && (args[1] == '/' || args[1] == '.')) {
                let content = document.querySelector(".content-wrap")
                let display = document.querySelector(".pc__display")
                
                lines.push('removing all files...', '...', '...', '...', 'completed')
                setTimeout(function () {
                    content.classList.add('content-wrap__off')
                    setTimeout(function() {
                        display.classList.add('pc__display__off')
                        setTimeout(function() {
                            display.classList.remove('pc__display__off')
                        }, 1500)
                    }, 150)
                }, 900)
            }
            return
        }
    }),
    sudo: extended([ "superuser access granted" ], {
        withArgs(lines, args) {
            return
        }, withAcceptor(commit, lines, args) {
            let stopped = true;
            var display = document.querySelector(".pc__display")
            display.classList.add('pc__display__sudo')

            var display = document.querySelector(".sudo-mode")
            display.classList.remove('sudo-mode__off')
            display.classList.add('sudo-mode__on')

            return { stop() {stopped = true} };
        }
    }),
    cowsay: extended([
        "  ____",
        "< {0:Mooo} >",
        "  ----",
        "       \\   ^ _ ^",
        "        \\  (o O) \\________",
        "             (__)\\              )\\/\\",
        "                      || -----w ||",
        "                      ||           ||",
    ], {
        withArgs(lines, args) {
            if (!args[0].length) return;
            lines[0] = "  " + '-'.repeat(args[0].length * 1.4);
            lines[2] = "  " + '-'.repeat(args[0].length * 1.4);
        }
    }),

    ping: extended([ "pong" ], {
        withArgs(lines, args) {
            lines.splice(0);
            lines.push(
                `PING ${args[0]}: 56 data bytes`,
                '',
            );
        },
        withAcceptor(commit, lines, args) {
            if (args.length < 1) return { stop() {} };
            const randomPing = () => (random(500 * 1000, 2000 * 1000) * .001).toFixed(3);
            const pingLine = ping => `64 bytes from ${args[0]}: icmp_seq=0 ttl=47 time=${ping} ms`;
            let timeoutId = null;
            const next = (ping = 0) => {
                if (ping)
                    return timeoutId = setTimeout(() => {
                        lines.push(pingLine(ping));
                        commit(lines);
                        if (lines.length < 7) next(randomPing());
                        else next();
                    }, ping);
                else
                    commit([ ...lines, ...pingResult ]);
            }
            next(randomPing());
            const pingResult = [
                '',
                `--- ${args[0]} ping statistics ---`,
                `5 packets transmitted, 5 packets received, 0.0 % packet loss`
            ];
            return { stop: () => timeoutId && clearTimeout(timeoutId) };
        },
    }),

    boi: extended([], {
        withAcceptor(commitLines) {
            let stopped = false;
            let i = 1;
            const intervalId = setInterval(() => {
                if (stopped) return clearInterval(intervalId);
                commitLines([ '– boi' + 'i'.repeat(++i) ]);
            }, 1000);
            return { stop() {stopped = true} };
        }
    })
};